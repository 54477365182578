/*FOOTER*/
.border-shape {
  background: $primaryGreenLight none repeat scroll 0 0;
  color: #fff;
  display: block;
  height: 3px;
  left: 0;
  margin: 20px auto;
  position: relative;
  right: 0;
  text-align: center;
  top: 0;
  width: 80px;
  &::before {
    background: $primaryGreenRegular none repeat scroll 0 0;
    bottom: 0;
    content: "";
    height: 1px;
    left: 80px;
    margin: 0 auto;
    position: absolute;
    text-align: center;
    top: 1px;
    width: 100px;
  }

  &::after {
    background: $primaryGreenLight none repeat scroll 0 0;
    bottom: 0;
    content: "";
    height: 1px;
    margin: 0 auto;
    position: absolute;
    right: 80px;
    text-align: center;
    top: 1px;
    width: 100px;
  }
}

footer {
  width: 100%;
  background-color: $primaryGreenDark;
  color: #fff;
  //  clear: both;
  //  position: relative;
  //    height: 200px;
  //  margin-top: -200px;
  .border-shape {
    width: 40%;
    &::before {
      width: 100%;
      left: 120px;
      @media screen and (max-width: $breakpoint-tablet) {
        width: 70%;
        left: 130px;
      }
    }
    &::after {
      width: 100%;
      right: 120px;
      @media screen and (max-width: $breakpoint-tablet) {
        width: 70%;
        right: 130px;
      }
    }
  }
  .bottom_content {
    section {
      padding: 1.5rem 2rem;
      display: flex;
      align-items: center;
      justify-content: Center;
    }
  }
}

.footer {
  &__footer-header {
    padding: 2rem;
    text-align: center;

    &__footer-contact {
      span {
        font-weight: lighter;
        margin-bottom: 25px;
      }
    }

    &__footer-disclaimer {
      p {
        color: $primaryGreenDark;
      }

      &__footer-disclaimer-header {
        font-size: 16px;
        margin-bottom: -45px;
      }
    }
  }
}
.bottom_content {
  a {
    margin: 0 20px;
    color: rgba(255, 255, 255, 0.7);
    transition: 0.5s;
    &:hover {
      color: rgba(255, 255, 255, 1);
    }
  }
}
.copyright {
  padding: 0.8em 0;
  background-color: #1e1e1e;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
}
@media (max-width: 820px) {
  .footer_header {
    padding: 1rem;
    display: block;
    section {
      display: block;
      text-align: center;
    }
  }
  footer {
    .bottom_content {
      section {
        padding: 1rem;
        display: block;
        text-align: center;
        a {
          padding: 1rem;
          font-size: 12px;
          margin: 0 5px;
          display: inline-block;
        }
      }
    }
  }
}

.fixed-footer {
  padding: 1em 0;
  background-color: #1e1e1e;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
}

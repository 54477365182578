@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap");

.adplist-section {
  padding: 1rem;
  height: 350px;
  box-shadow: rgba(142, 151, 158, 0.15) 0px 4px 19px 0px;
  border-radius: 12px;
  overflow: hidden;
  width: 100%;
  max-width: 380px;
}

.attention {
  background: #080715;
  padding-bottom: 15rem;
}

@media screen and (max-width: $breakpoint-tablet) {
  .attention {
    padding-bottom: 1rem;
  }
}

.attention .card.attention-card {
  //  box-shadow: 2px 2px 5px rgb(0, 0, 0);
  //  border: 1px solid #212529;
  border-radius: 0;
  background: #0d0f12;
  margin-bottom: 2rem;
  width: 22rem;
  //  height: 20rem;
}

.attention .card.attention-card .card-body {
  background: #000000;
  color: #b8c1cc;
}

.attention .card.attention-card .card-title {
  font-size: 48px;
  font-family: "Lato", serif;
  font-optical-sizing: auto;
  font-weight: bold;
}

.attention .card.attention-card .card-text {
  font-size: 16px;
  font-weight: normal;
  color: #ffffff;
  font-family: "Lato", serif;
}

.attention .card.attention-card .card-footer {
  background: #171717;
  //  border-bottom-left-radius: 30px !important;
  //  border-bottom-right-radius: 30px !important;
  padding: 1rem 1rem;
}

.attention .card.attention-card .card-img-top {
  border-radius: 0 !important;
  border: 0px;
  background: #000;
}

.attention .card.attention-card .card-img-top img {
  width: 10em;
}

@media screen and (max-width: $breakpoint-tablet) {
  .attention .card.attention-card .card-img-top img {
    width: 7rem;
  }
}

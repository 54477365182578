//attention-fluid
.attention-two-fluid .card {
  background: #000000;
  //  border: 1px solid #212529;
  height: 20rem;
  //  width: 60rem;
  border-radius: 0;
  //  position: relative;
  //  display: flex;
  //  flex-direction: row;
  //  align-self: center;
}
.attention-two-fluid .card:hover {
  background: #171717 !important;
  transition: 0.5s all;
  cursor: pointer;
}

@media screen and (max-width: $breakpoint-tablet) {
  .attention-two-fluid .card {
    //margin-bottom: 5rem;
    height: 22rem;
  }
}

.attention-two-fluid .card .card-bottom-img img {
  position: absolute;
  margin: auto;
  //  left: 0;
  right: 5rem;
  //  top: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  width: 35rem;
  //  border-top: 10px solid #555555;
  //  border-left: 10px solid #555555;
  //  border-radius: 8px;
}

@media screen and (max-width: $breakpoint-tablet) {
  .attention-two-fluid .card .card-bottom-img img {
    position: absolute;
    width: 11rem;
    right: -11rem;
    left: 0;
    bottom: 18rem;
  }
}

.attention-two-fluid .card-title {
  font-size: 20px;
  //  text-align: left;
  //  margin-bottom: 3rem;
  color: #cdceff;
}

.attention-two-fluid .card-subtitle {
  width: 40rem;
}

@media screen and (max-width: $breakpoint-tablet) {
  .attention-two-fluid .card-subtitle {
    width: 19rem;
  }
}

.attention-two-fluid .card-title .card-date {
  //  text-align: right;
  font-size: 12px;
  //  position: absolute;
  //  top: 1.5rem;
  //  right: 1.5rem;
}

.attention-two-fluid .card-text {
  font-size: 36px;
  text-align: left;
  color: #b8c1cc;
}

@media screen and (max-width: $breakpoint-tablet) {
  .attention-two-fluid .card-text {
    font-size: 28px;
  }
}

.attention-two-fluid .card .card-footer {
  background: #171717;
  //  border-bottom-left-radius: 30px !important;
  //  border-bottom-right-radius: 30px !important;
  padding: 1rem 1rem;
}

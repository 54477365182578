.connections {
  background: #000;
}

//.connections figure

.connections figure {
  background: #000000 !important;
  padding-bottom: 2rem;
}

.connections figcaption {
  margin-bottom: 1.5rem;
  font-size: 32px;
  font-family: "Bricolage Grotesque", serif;
  color: #ffffff;
}

.connections svg {
  background: #000;
  border: 0.5px solid #555;
  border-radius: 10px;
  width: 40rem;

  //  margin: 2rem;
}

@media screen and (max-width: $breakpoint-tablet) {
  .connections svg {
    width: 20rem;
    height: 20rem;
  }

  .connections figcaption {
    font-size: 24px;
  }
}

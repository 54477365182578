@import url("https://fonts.googleapis.com/css2?family=Playfair+Display+SC:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Playfair+Display+SC:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200..800&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Amita:wght@400;700&family=Righteous&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Lato", serif;
  background-color: #ffffff;
}

a {
  text-decoration: none;
  outline: none !important;
  color: #1aa78d;
}

h1,
h2,
h3 {
  font-family: "Bricolage Grotesque", serif;
}

.headerBg {
  background-image: url("../img/bg/bgpp.svg");
  padding: 8rem 6rem 8rem 6rem;
  color: #fff;
  display: flex;
  align-items: end;
}
@media screen and (max-width: 768px) {
  .headerBg {
    padding: 8rem 1rem 3rem 1rem;
  }
}

@media screen and (max-width: 768px) {
  .header-btn-with-anim {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.header-btn-with-anim a {
  margin-right: 1.5rem;
}

@media screen and (max-width: 768px) {
  .header-btn-with-anim a {
    padding: 8px 0px;
    margin-top: 1rem;
    font-size: 14px;
    margin-right: 0;
  }
}

@media screen and (max-width: 768px) {
  .header .ramadan__project-row {
    padding: 1rem;
  }
}

.header h1 {
  font-family: "Bricolage Grotesque", serif;
  font-weight: bold;
  font-size: 42px;
  line-height: 130%;
  color: #070547;
}

@media screen and (max-width: 768px) {
  .header h1 {
    font-size: 27px;
    line-height: 1.85rem;
  }
}
@media screen and (max-width: 768px) {
  .header-image-card img {
    margin-top: 2rem;
    width: 100%;
  }
}
.more {
  margin-bottom: 1.5rem;
}

.glowable-text {
  position: relative;
  z-index: 2;
  font-size: 18px;
  color: #b8c1cc;
}

.bg-glow {
  height: 15rem;
  width: 15rem;
  filter: blur(150px);
  border-radius: 150px;
  z-index: 0;
  position: absolute;
  opacity: 1;
}

.glow {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.glowable-text .card.card-1 {
  display: grid;
  color: white;
}

@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}
.glowable-text .card {
  border-radius: 8px;
}

.glowable-text .card.card-1 .card-body {
  text-align: left;
}

.glowable-text .card.card-2 {
  background-color: #e1eafd;
  padding: 1rem 1rem 10rem 1rem;
  position: relative;
  border: none;
}

.intro .card-2 .card-body {
  padding-right: 3rem;
  height: auto;
}

.header-image-card img {
  width: 100%;
}

.card.card-2 .btnCTA {
  position: absolute;
  bottom: 20px;
}

.glowable-text .card.card-2 h4 {
  width: 32rem;
  line-height: 140%;
  font-size: 32px;
  color: #070547;
  font-weight: 400;
  font-family: "Lato", serif;
  font-optical-sizing: auto;
  font-style: normal;
}

@media screen and (max-width: 768px) {
  .glowable-text .card.card-2 h4 {
    width: auto;
    line-height: 140%;
    font-size: 24px;
  }
}
.glowable-text .card.card-3 {
  border: none;
  background-color: #e1eafd;
}

.glowable-text .card.card-3 .card-3-text {
  color: #070547;
}

.glowable-text:hover {
  cursor: pointer;
}

[glow] .glowable-text {
  color: #000000;
}

@media screen and (max-width: 768px) {
  .glow {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
  .glowable-text {
    font-size: 14px;
  }
  .glowable-text .card {
    max-width: 100%;
  }
}
.fun-text {
  padding-top: 5rem;
  padding-bottom: 10rem;
}

.fun-text h1 {
  font-family: "Amita", serif;
  font-size: 112px;
  color: #0d0f12;
}

@media screen and (max-width: 768px) {
  .fun-text {
    padding-top: 5rem;
  }
  .fun-text h1 {
    font-family: "Amita", serif;
    font-size: 32px;
  }
  .bg-glow {
    height: 23rem;
    width: 8rem;
  }
}
.header {
  margin: 0 auto;
  width: 100%;
  max-width: 1300px;
}
@media screen and (max-width: 768px) {
  .header {
    display: block;
  }
}
.header__flexTwo {
  width: 30%;
  position: relative;
  display: inline-block;
}
@media screen and (max-width: 768px) {
  .header__flexTwo {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .header__headerCTA {
    font-size: 14px;
  }
}
.header__gradientBg {
  height: 602px;
  margin: -180px auto 0;
  width: 1146px;
  position: relative;
}
@media screen and (max-width: 768px) {
  .header__gradientBg {
    width: 130px;
  }
}
@media screen and (max-width: 1024px) {
  .header__gradientBg {
    width: 130px;
  }
}
.header__gradientBg__circle1 {
  background-color: rgba(11, 121, 101, 0.6980392157);
  opacity: 0.7;
  filter: blur(135px);
  height: auto;
  padding-bottom: 46%;
  width: 70%;
  top: -16.3%;
  left: -14.6%;
}
.header__gradientBg__circle2 {
  background-color: rgba(13, 164, 234, 0.7803921569);
  opacity: 0.65;
  filter: blur(100px);
  opacity: 0.6;
  height: auto;
  padding-bottom: 47.5%;
  width: 70%;
  top: -26.1%;
  left: 21.9%;
}
.header__gradientBg__circle3 {
  background-color: #17d8b5;
  opacity: 0.63;
  webkit-filter: blur(102px);
  filter: blur(102px);
  height: auto;
  padding-bottom: 33.3%;
  width: 50.3%;
  top: 51.8%;
  left: 8.7%;
}
.header__gradientBg__circle4 {
  background-color: #f5fffd;
  opacity: 0.43;
  webkit-filter: blur(140px);
  filter: blur(140px);
  height: auto;
  padding-bottom: 40%;
  width: 40%;
  top: 53.7%;
  right: 64.8%;
}
.header__gradientBg__circle5 {
  background-color: #00bb98;
  opacity: 0.5;
  webkit-filter: blur(136px);
  filter: blur(136px);
  height: auto;
  padding-bottom: 100%;
  width: 70%;
  top: 69.3%;
  right: 0;
}
.header__gradCircle {
  position: absolute;
  border-radius: 50%;
}
.header__content-h1 {
  font-size: 72px;
  color: #141414;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .header__content-h1 {
    font-size: 28px;
  }
}
.header__content-badges {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
}
@media screen and (max-width: 768px) {
  .header__content-badges {
    margin-top: 1.5rem;
  }
}
.header__content-badges__cred-img {
  width: 2rem;
  border-radius: 50%;
  margin-right: 2rem;
}
@media screen and (max-width: 768px) {
  .header__content-badges__cred-img {
    width: 1.5rem;
    margin-right: 1rem;
  }
}
.header__content-links {
  font-size: 22px;
  font-weight: 500;
  color: #222222;
  text-align: center;
  text-decoration: none;
  margin-top: 1rem;
}
@media screen and (max-width: 768px) {
  .header__content-links {
    font-size: 16px;
    margin-top: 1rem;
  }
}
.header__content-span {
  font-size: 24px;
  color: #e8e6e6;
}
.bg-grey {
  background-color: #141414;
}

.nav-center-text {
  font-size: 1.5rem;
}

.navbar-brand {
  position: relative;
}

.nav-job-role {
  position: absolute;
  top: 0;
  left: 5rem;
  font-family: "Lato", serif;
  font-size: 12px;
  justify-content: center;
  -webkit-text-stroke: 0.5px #5899e1;
  -webkit-text-fill-color: #5899e1;
  border: 1px solid #5899e1;
  padding: 4px;
  border-radius: 4px;
}

.navbar-menu-flex {
  display: flex;
  flex-direction: row;
}

.navbar-menu-works {
  display: flex;
  flex-direction: row;
  justify-content: start;
}

@media screen and (max-width: 768px) {
  .navbar-menu-works {
    justify-content: center;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 768px) {
  .navbar-menu-flex {
    display: block;
  }
  .navbar-brand {
    font-size: 14px;
  }
}
@media screen and (max-width: 768px) {
  .nav-center-text {
    display: none;
  }
  .navbar-toggler {
    display: none;
  }
}
.navbar {
  padding: 15px;
  transition: all 0.5s linear;
  z-index: 1020;
  border-bottom: 0.5px solid #181a59;
  background-image: url("../img/bg/navbg.png");
  background-size: cover;
}
.navbar .nav-logo {
  width: 350px;
}
@media screen and (max-width: 768px) {
  .navbar .nav-logo {
    width: 215px;
  }
}
.navbar-nav {
  z-index: 1020;
}
.navbar-nav .nav-item .nav-link.active {
  color: #1aa78d;
}
.navbar-nav .nav-item .nav-link {
  font-size: 18px;
  color: #fff;
  border-radius: 3px;
  padding: 5px 10px 5px 10px;
  margin-left: 12px;
  margin-top: 10px;
  display: inline-block;
}
.navbar-nav .nav-item .nav-link:hover, .navbar-nav .nav-item .nav-link:focus {
  color: #004b3e;
}
.navbar-nav .nav-item .nav-link.nav-dropdown-link {
  font-size: 16px;
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
  padding: 5px;
}
.navbar-nav .nav-item .nav-link .nav-link.nav-link-green {
  background-color: #1aa78d;
}
@media screen and (max-width: 768px) {
  .navbar-nav .nav-item .nav-link {
    color: #141414;
  }
}
@media screen and (max-width: 768px) {
  .navbar-toggler {
    background: rgba(200, 255, 245, 0.6196078431);
  }
}
.navbar .nav-link-black {
  color: #141414 !important;
}
.navbar .nav-item .nav-link.nav-link-green {
  background-color: #1aa78d !important;
}
.navbar .nav-link-white {
  color: #f7f7f7;
}
@media screen and (max-width: 768px) {
  .navbar .navbar-collapse {
    background-color: #fff;
    padding: 15px 15px 25px 8px;
    border-radius: 7px;
    box-shadow: 0px 8px 1rem 0px rgba(0, 0, 0, 0.23);
    margin-top: 1rem;
  }
}
@media screen and (max-width: 768px) {
  .navbar {
    padding-top: 1.5rem;
  }
}

.bg-transp {
  background: #0d0f12;
}

.navbar-shadow {
  background: #fff;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.projects {
  padding: 30px 0 0 0;
  width: 100%;
}
.projects__title {
  margin-bottom: 75px;
}
@media screen and (max-width: 768px) {
  .projects__title {
    margin-top: 0;
  }
}
.projects__saveProject {
  position: absolute;
  top: 1rem;
  right: 2rem;
}
@media screen and (max-width: 768px) {
  .projects__project-row {
    --bs-gutter-y: 0.25rem;
    --bs-gutter-x: 0.25rem;
    margin-right: -10px;
    margin-left: -10px;
  }
}
.projects__project-link {
  text-decoration: none;
}
.projects__project-orgBold {
  font-size: 13px;
}
.projects__project-org {
  margin-top: 20px;
}
@media screen and (max-width: 768px) {
  .projects__project-org {
    font-size: 12px;
  }
}
.projects__project-btn {
  background: #070547;
  color: #f7f7f7;
  border-color: #141414;
  border-radius: 1.5rem;
  padding: 10px 30px;
}
.projects__project-btn:hover {
  background: #e1eafd;
  color: #070547;
  border: 0.5px solid #070547;
}
.projects__project-btn:hover {
  color: #e8e6e6;
}
@media screen and (max-width: 768px) {
  .projects__project-btn {
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 0;
    margin-left: 0;
  }
}
.projects__SaveProject {
  position: absolute;
  top: 13.5rem;
  right: 1rem;
  z-index: auto;
  padding: 5px;
  color: #004b3e;
  border-radius: 50%;
  border: 1px solid #004b3e;
  border-color: #004b3e;
}
.projects__progress-bar {
  color: #141414;
}
@media screen and (max-width: 768px) {
  .projects__progress-bar {
    font-size: 12px;
  }
}
.projects__project-heading {
  font-size: 22px;
  margin-top: 5px;
  color: #004b3e;
  font-weight: bold;
  text-decoration: none;
}
@media screen and (max-width: 768px) {
  .projects__project-heading {
    font-size: 14px;
    margin-bottom: 20px;
  }
}
.projects__project-icon {
  margin-bottom: 25px;
}
@media screen and (max-width: 768px) {
  .projects__project-card {
    margin-right: 0;
  }
}
.projects__project-btntag {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 0;
}
@media screen and (max-width: 768px) {
  .projects__project-btntag {
    display: block;
    text-align: center;
    margin-bottom: 10px;
  }
}
.projects__project-created {
  font-style: italic;
  margin-top: -10px;
}
@media screen and (max-width: 768px) {
  .projects__project-created {
    font-size: 11px;
    margin-bottom: -20px;
  }
}
@media screen and (max-width: 768px) {
  .projects__project-tag {
    font-size: 10px;
    border-radius: 0;
    text-align: left;
  }
}
.projects__project-img {
  width: 100%;
  height: 200px;
  -o-object-fit: cover;
     object-fit: cover;
}
@media screen and (max-width: 768px) {
  .projects__project-img {
    height: 115px;
    -o-object-fit: fill;
       object-fit: fill;
  }
}
@media screen and (max-width: 768px) {
  .projects__project-cardBody {
    padding: 10px 10px;
  }
}

.zakat {
  padding: 0 0 0 0;
  margin-bottom: 5rem;
}
@media screen and (max-width: 768px) {
  .project_vdo_mid {
    width: 15rem;
  }
}

.measurer_img {
  width: 100%;
}
@media screen and (max-width: 768px) {
  .measurer_img {
    margin-top: 0.5rem;
    width: 16rem;
  }
}

@media screen and (max-width: 768px) {
  .project_vdo_sm {
    width: 15rem;
  }
}

.title-icon {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: start;
}

.title-icon img {
  height: 100%;
  margin-left: 1rem;
}

@media screen and (max-width: 768px) {
  .title-icon {
    display: flex;
    justify-content: center;
  }
}
.details {
  padding: 4rem;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .details {
    padding: 8rem 1rem;
  }
}
.details__btn {
  background: #070547;
  color: #f7f7f7;
  border-color: #141414;
  border-radius: 1.5rem;
  padding: 10px 30px;
}
.details__btn:hover {
  background: #e1eafd;
  color: #070547;
  border: 0.5px solid #070547;
}
.details__btn:hover {
  color: #e8e6e6;
}
.details__facebookIDDiv {
  margin-top: 25px;
}
.details__heading {
  margin-top: 15px;
  margin-bottom: 15px;
  color: #004b3e;
  font-weight: bold;
}
.details__img {
  width: auto;
  height: 300px;
  -o-object-fit: contain;
     object-fit: contain;
}
@media screen and (max-width: 768px) {
  .details__img {
    width: 320px;
  }
}
.details__description {
  font-size: 22px;
  margin-top: 2rem;
  color: #141414;
  font-weight: normal;
}
.details__social {
  margin-top: 2rem;
  margin-bottom: 3rem;
}
.details__projectLink {
  margin-left: 5px;
  color: #0a58ca;
}
.details__card {
  position: relative;
}
@media screen and (max-width: 768px) {
  .details__card {
    margin-right: 0;
  }
}
.details__org {
  margin-top: 20px;
}
.details__created {
  font-style: italic;
  margin-top: -10px;
}
.details__tag {
  position: absolute;
  top: 0;
  right: 1rem;
}
@media screen and (max-width: 768px) {
  .details__tag {
    font-size: 10px;
  }
}
.details__dua {
  margin-top: 3rem;
  font-weight: 600;
  color: #004b3e;
}

.bi-send-check-fill {
  margin-right: 15px;
  width: 24px;
  height: 24px;
}

.adplist-section {
  padding: 1rem;
  height: 350px;
  box-shadow: rgba(142, 151, 158, 0.15) 0px 4px 19px 0px;
  border-radius: 12px;
  overflow: hidden;
  width: 100%;
  max-width: 380px;
}

.attention {
  background: #080715;
  padding-bottom: 8rem;
}

.attention-one-middle-text {
  background: linear-gradient(93.4082deg, rgb(185, 178, 178) 0%, rgba(255, 255, 255, 0.5) 100%);
  display: inline-block;
  -webkit-background-clip: text;
          background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media screen and (max-width: 768px) {
  .attention {
    padding-bottom: 1rem;
  }
}
.attention .card.attention-card {
  border-radius: 0;
  background: #0d0f12;
  margin-bottom: 2rem;
  width: 22rem;
}

.attention .card.attention-card .card-body {
  background: #000000;
  color: #b8c1cc;
}

.attention .card.attention-card .card-title {
  font-size: 48px;
  font-family: "Bricolage Grotesque", serif;
  font-optical-sizing: auto;
  font-weight: bold;
}

.attention .card.attention-card .card-text {
  font-size: 14px;
  font-weight: lighter;
  color: #ffffff;
  font-family: "Courier Prime", monospace;
}

.attention .card.attention-card .card-footer {
  background: #171717;
  padding: 1rem 1rem;
}

.attention .card.attention-card .card-img-top {
  border-radius: 0 !important;
  border: 0px;
  background: #000;
}

.attention .card.attention-card .card-img-top img {
  width: 10em;
}

@media screen and (max-width: 768px) {
  .attention .card.attention-card .card-img-top img {
    width: 7rem;
  }
}
.attention-one-left .card {
  background: #000000;
  border: 1px solid #212529;
  height: 20rem;
  width: 22rem;
  border-radius: 0;
  position: relative;
}

.attention-one-left .card:hover {
  background: #171717 !important;
  transition: 0.5s all;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .attention-one-left .card {
    margin-bottom: 5rem;
  }
}
.attention-one-left .card .card-bottom-img img {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 0rem;
  text-align: center;
  z-index: 1;
  width: 10rem;
}

.attention-one-left .card-title {
  font-size: 20px;
  text-align: left;
  margin-bottom: 3rem;
  color: #cdceff;
}

.attention-one-left .card-title .card-date {
  text-align: right;
  font-size: 12px;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
}

.attention-one-left .card-text {
  font-size: 36px;
  text-align: left;
  color: #b8c1cc;
}

.attention-one-left .card .card-footer {
  background: #171717;
  padding: 1rem 1rem;
}

.attention-one-right .card {
  background: #000000;
  border: 1px solid #212529;
  height: 20rem;
  width: 22rem;
  border-radius: 0;
  position: relative;
}

.attention-one-right .card-title {
  font-size: 20px;
  text-align: left;
  margin-bottom: 3rem;
  color: #cdceff;
}

.attention-one-right .card-title .card-date {
  text-align: right;
  font-size: 12px;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
}

.attention-one-right p {
  font-size: 14px;
  text-align: left;
}

.attention-one-right .card-text {
  color: #eeeeee;
}

.attention-one-right .card-text:hover {
  transition: 0.5s all;
  color: #3e42c1;
  cursor: pointer;
}

.attention-one-right .card .card-footer {
  background: #171717;
  padding: 1rem 1rem;
}

.adplist-section {
  padding: 1rem;
  height: 350px;
  box-shadow: rgba(142, 151, 158, 0.15) 0px 4px 19px 0px;
  border-radius: 12px;
  overflow: hidden;
  width: 100%;
  max-width: 380px;
}

.attention {
  background: #080715;
  padding-bottom: 15rem;
}

@media screen and (max-width: 768px) {
  .attention {
    padding-bottom: 1rem;
  }
}
.attention .card.attention-card {
  border-radius: 0;
  background: #0d0f12;
  margin-bottom: 2rem;
  width: 22rem;
}

.attention .card.attention-card .card-body {
  background: #000000;
  color: #b8c1cc;
}

.attention .card.attention-card .card-title {
  font-size: 48px;
  font-family: "Lato", serif;
  font-optical-sizing: auto;
  font-weight: bold;
}

.attention .card.attention-card .card-text {
  font-size: 16px;
  font-weight: normal;
  color: #ffffff;
  font-family: "Lato", serif;
}

.attention .card.attention-card .card-footer {
  background: #171717;
  padding: 1rem 1rem;
}

.attention .card.attention-card .card-img-top {
  border-radius: 0 !important;
  border: 0px;
  background: #000;
}

.attention .card.attention-card .card-img-top img {
  width: 10em;
}

@media screen and (max-width: 768px) {
  .attention .card.attention-card .card-img-top img {
    width: 7rem;
  }
}
.attention-two-fluid .card {
  background: #000000;
  height: 20rem;
  border-radius: 0;
}

.attention-two-fluid .card:hover {
  background: #171717 !important;
  transition: 0.5s all;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .attention-two-fluid .card {
    height: 22rem;
  }
}
.attention-two-fluid .card .card-bottom-img img {
  position: absolute;
  margin: auto;
  right: 5rem;
  bottom: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  width: 35rem;
}

@media screen and (max-width: 768px) {
  .attention-two-fluid .card .card-bottom-img img {
    position: absolute;
    width: 11rem;
    right: -11rem;
    left: 0;
    bottom: 18rem;
  }
}
.attention-two-fluid .card-title {
  font-size: 20px;
  color: #cdceff;
}

.attention-two-fluid .card-subtitle {
  width: 40rem;
}

@media screen and (max-width: 768px) {
  .attention-two-fluid .card-subtitle {
    width: 19rem;
  }
}
.attention-two-fluid .card-title .card-date {
  font-size: 12px;
}

.attention-two-fluid .card-text {
  font-size: 36px;
  text-align: left;
  color: #b8c1cc;
}

@media screen and (max-width: 768px) {
  .attention-two-fluid .card-text {
    font-size: 28px;
  }
}
.attention-two-fluid .card .card-footer {
  background: #171717;
  padding: 1rem 1rem;
}

.attention-two-left .card {
  background: transparent;
  height: 20rem;
  width: 100%;
  border-radius: 0;
  position: relative;
}

.attention-two-left .card:hover {
  transition: 0.5s all;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .attention-two-left .card {
    margin-bottom: 5rem;
  }
}
.attention-two-left .card .card-bottom-img img {
  margin-top: 0.5rem;
  z-index: 1;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .attention-two-left .card .card-bottom-img img {
    width: 21rem;
  }
}
.attention-two-left .card-title {
  font-size: 16px;
  text-align: center;
  color: #3e42c1;
}

.attention-two-left .card-title .card-date {
  text-align: right;
  font-size: 12px;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
}

.attention-two-left .card-text {
  font-size: 20px;
  text-align: center;
  color: #b8c1cc;
}

.attention-two-left .card .card-footer {
  background: #171717;
  padding: 1rem 1rem;
}

.attention-two-middle .card {
  background: transparent;
  border-radius: 0;
}

.attention-two-middle .card:hover {
  transition: 0.5s all;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .attention-two-middle .card {
    margin-bottom: 5rem;
  }
}
.attention-two-middle .card .card-bottom-img img {
  margin: auto;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .attention-two-middle .card .card-bottom-img img {
    bottom: -15rem;
    width: 90%;
  }
}
.attention-two-middle .card-title {
  font-size: 16px;
  color: #3e42c1;
}

.attention-two-middle .card-title .card-date {
  font-size: 12px;
}

.attention-two-middle .card-text {
  font-size: 20px;
  color: #b8c1cc;
}

.attention-two-middle .card .card-footer {
  background: #171717;
  padding: 1rem 1rem;
}

.attention-two-right .card {
  background: #000000;
  border-radius: 0;
  position: relative;
}

.attention-two-right .card-title {
  font-size: 16px;
  text-align: left;
  color: #3e42c1;
}

.attention-two-right .card-title .card-date {
  text-align: right;
  font-size: 12px;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
}

.attention-two-right p {
  font-size: 14px;
  text-align: right;
}

.attention-two-right .card-text {
  color: #b8c1cc;
  font-size: 20px;
}

.attention-two-right .card-text:hover {
  transition: 0.5s all;
  cursor: pointer;
}

.attention-two-right .card .card-bottom-img img {
  margin: auto;
  z-index: 1;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .attention-two-right .card .card-bottom-img img {
    bottom: -2rem;
    width: 22rem;
  }
}
.attention-two-right .card .card-footer {
  background: #171717;
  padding: 1rem 1rem;
}

.bg-banner {
  background-color: #fff1b5;
  margin: 5rem 0 6rem 0;
  padding: 3rem;
}

.banner-text {
  position: relative;
  text-align: center;
}

.banner-text .white-background {
  position: absolute;
  top: 50%;
  left: 30%;
  width: 50%;
  height: 30%;
  background-color: #ffffff;
  transform: rotate(-5deg);
  z-index: 0;
}

.banner-text span {
  position: relative;
  z-index: 1;
}

.banner-text .large-text {
  color: #000000;
  font-size: 4rem;
  font-weight: bold;
  font-family: "Bricolage Grotesque", serif;
}

.banner-text .small-text {
  color: #000000;
  font-family: "Lato", serif;
  font-size: 18px;
  margin-left: 0.5rem;
}

.animations {
  padding-top: 10rem;
}

.work-header {
  padding: 5rem 0 5rem 0;
}

.work-header-subtext {
  font-size: 18px;
  color: #555555;
}

.work-header-img {
  text-align: center;
}

.work-header-img img {
  width: 30rem;
}

.btnCTAOutline {
  border: 1px solid #333333;
  color: #141414;
  border-color: #e8e6e6;
  border-radius: 1.5rem;
  padding: 10px 30px;
  margin-left: 0.8rem;
}
.btnCTAOutline:hover {
  background: linear-gradient(54deg, #ffffff, rgba(240, 238, 238, 0.7490196078));
  color: #141414;
}

@media screen and (max-width: 768px) {
  .work-header {
    padding: 2.5rem 0 5rem 0;
  }
  .btnCTAOutline {
    margin-top: 2rem;
    margin-left: 0rem;
  }
  .work-header .work-header-subtext {
    font-size: 14px;
  }
  .work-header .work-header-img {
    margin-top: 3rem;
  }
  .work-header .work-header-img img {
    width: 17.5rem;
    text-align: center;
  }
  .work-header .work-header-img h5 {
    font-size: 14px;
  }
}
.works .col-md-6 img {
  background: #252424;
  padding: 0.8rem;
  border-radius: 15px;
}

.reviews {
  padding: 5rem;
}

.reviews .card {
  background-color: #ebebeb;
  padding: 1.25rem;
  border: 1px solid #e5e5e5 !important;
  border-radius: 1.15rem;
  height: 100%;
  margin-bottom: 1rem;
}

@media screen and (max-width: 768px) {
  .reviews {
    padding: 1.5rem;
  }
  .reviews .card {
    padding: 0.5rem 0.5rem;
    height: auto;
  }
  .reviews .col-12 {
    margin-bottom: 3rem;
  }
}
.reviews .card-body {
  height: 18rem;
  padding: 1.25rem 0.25rem;
}

.reviews .card-text {
  color: #000000;
  font-size: 1.05rem;
  margin-bottom: 3.5rem;
}

.reviews img.card-img-top {
  width: 30px;
}

.review-card-person {
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 20px;
}

.review-card-person-info {
  display: flex;
  flex-direction: row;
  justify-content: start;
}

.review-card-person img.person-img {
  margin-right: 15px;
  width: 80px;
  height: 100%;
}

.review-card-person .card-text-bottom {
  font-size: 20px;
  margin-bottom: 0;
}

.review-card-person .card-subt-text-bottom {
  font-size: 14px;
  color: #737373;
  line-height: 1.5;
}

@media screen and (max-width: 768px) {
  .reviews .card-body {
    height: auto;
    padding: 1.5arem 0.25rem;
  }
  .reviews .card-text {
    font-size: 1rem;
    margin-bottom: 2.5rem;
  }
  .review-card-person {
    position: relative;
  }
  .review-card-person img.person-img {
    width: 40px;
    height: 40px;
  }
}
.connections {
  background: #000;
}

.connections figure {
  background: #000000 !important;
  padding-bottom: 2rem;
}

.connections figcaption {
  margin-bottom: 1.5rem;
  font-size: 32px;
  font-family: "Bricolage Grotesque", serif;
  color: #ffffff;
}

.connections svg {
  background: #000;
  border: 0.5px solid #555;
  border-radius: 10px;
  width: 40rem;
}

@media screen and (max-width: 768px) {
  .connections svg {
    width: 20rem;
    height: 20rem;
  }
  .connections figcaption {
    font-size: 24px;
  }
}
.connections {
  background: #000;
}

.connections figure {
  background: #000000 !important;
  padding-bottom: 2rem;
}

.connections figcaption {
  margin-bottom: 1.5rem;
  font-size: 32px;
  font-family: "Bricolage Grotesque", serif;
  color: #ffffff;
}

.connections svg {
  background: #000;
  border: 0.5px solid #555;
  border-radius: 10px;
  width: 40rem;
}

@media screen and (max-width: 768px) {
  .connections svg {
    width: 20rem;
    height: 20rem;
  }
  .connections figcaption {
    font-size: 24px;
  }
}
.ramadan {
  width: 100%;
}
@media screen and (max-width: 768px) {
  .ramadan.ngos {
    margin-top: 0rem;
  }
}
.ramadan__title {
  font-size: 36px;
  text-align: left;
  margin-bottom: 15px;
}
@media screen and (max-width: 768px) {
  .ramadan__title {
    margin-top: 75px;
    margin-bottom: 35px;
    font-size: 24px;
  }
}
.ramadan__subTitle {
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: #333;
  margin-bottom: 1.5rem;
}
@media screen and (max-width: 768px) {
  .ramadan__subTitle {
    font-size: 14px;
  }
}
.ramadan__project-link {
  text-decoration: none;
}
.ramadan__project-btn {
  background: #070547;
  color: #f7f7f7;
  border-color: #141414;
  border-radius: 1.5rem;
  padding: 10px 30px;
  transition: all 0.2s ease-in;
}
.ramadan__project-btn:hover {
  background: #e1eafd;
  color: #070547;
  border: 0.5px solid #070547;
}
.ramadan__project-heading {
  font-size: 22px;
  margin-top: 5px;
  color: #004b3e;
  font-weight: bold;
  text-decoration: none;
}
.ramadan__project-icon {
  margin-bottom: 25px;
}
.ramadan__project-card {
  position: relative;
  margin-right: 1.5rem;
}
@media screen and (max-width: 768px) {
  .ramadan__project-card {
    margin-right: 0;
  }
}
.ramadan__project-org {
  margin-top: 20px;
}
.ramadan__project-created {
  font-style: italic;
  margin-top: -10px;
}
@media screen and (max-width: 768px) {
  .ramadan__project-tag {
    font-size: 10px;
  }
}
.ramadan__project-btntag {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 0;
}
@media screen and (max-width: 768px) {
  .ramadan__project-btntag {
    text-align: center;
    margin-bottom: 10px;
  }
}
.ramadan__project-row {
  position: relative;
  padding: 1.5rem;
}
.ramadan__project-img {
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
}
.ramadan__project-div-2 {
  position: relative;
}
.ramadan__project-img_2 {
  width: 600px;
  z-index: -1;
}
@media screen and (max-width: 768px) {
  .ramadan__project-img_2 {
    width: 330px;
  }
}
.ramadan__project-vdo-2 {
  position: absolute;
  top: -11.6rem;
  left: 4.7rem;
}
@media screen and (max-width: 768px) {
  .ramadan__project-vdo-2 {
    top: -15.6rem;
    left: 2.7rem;
    width: 15rem;
  }
}
.ramadan__project-vdo {
  border-radius: 3rem;
}

@media screen and (max-width: 768px) {
  .ramadan-page {
    padding-top: 1rem;
  }
}
.ramadan-page__title {
  margin-top: 35px;
  margin-bottom: 35px;
}

.review-section {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .review-section {
    display: block;
    text-align: center;
    padding-bottom: 25px;
  }
}

.blog {
  padding: 1.5rem 15rem 5rem 15rem;
}
@media screen and (max-width: 768px) {
  .blog {
    padding: 1.5rem 1rem;
  }
}
.blog__img {
  width: 100%;
  height: 25rem;
  margin-top: 5.5rem;
}
@media screen and (max-width: 768px) {
  .blog__img {
    height: 10rem;
  }
}

.lds-dual-ring {
  display: inline-block;
  width: 50px;
  height: 50px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 8px solid #0b7965;
  border-color: #0b7965 transparent #0b7965 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.credentials__cred-img {
  width: 6.5vw;
  height: 10vh;
  padding: 4px;
}
@media screen and (max-width: 768px) {
  .credentials__cred-img {
    width: 12vw;
    height: 5vh;
    padding: 1px;
  }
}

.contact {
  padding-top: 5rem;
  padding-bottom: 15rem;
}
.contact__card {
  padding: 20px 0 0 0;
  display: inline-block;
  border: none;
}
.contact__title {
  color: #141414;
  margin-bottom: 25px;
  font-size: 30px;
}
.contact__sub-title {
  font-size: 18px;
  text-align: justify;
  line-height: 30px;
  margin-top: 25px;
  margin-bottom: 45px;
}
.contact__sub-title span {
  color: #004b3e;
}
.contact__about-disclaimer p {
  color: #004b3e;
}
.contact__about-disclaimer__about-disclaimer-header {
  font-size: 16px;
  letter-spacing: 14px;
  margin-bottom: -45px;
}

.btnCTA_outline {
  color: #f7f7f7;
  border: 0.5px solid #cccccc;
  border-radius: 1.5rem;
  padding: 10px 30px;
}
.btnCTA_outline:hover {
  background: linear-gradient(54deg, #333333, rgba(51, 51, 51, 0.7490196078));
  color: #f7f7f7;
}

.contact .card {
  display: inline-block;
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  border-radius: 6px;
  color: rgba(0, 0, 0, 0.87);
  background: transparent;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.text-danger {
  color: #f44336;
}

.card .table-danger {
  color: #ffffff;
  border-radius: 6px;
}

.card .table {
  padding: 20px 20px 0 20px;
}

.contact .card .table > :not(:first-child) {
  border: none !important;
}

.card .table-danger .author a,
.card .table-danger .ftr .stats,
.card .table-danger .category,
.card .table-danger .card-description .card .table-danger .card-caption {
  color: rgba(255, 255, 255, 0.8);
}

.card .card-caption {
  margin-bottom: 1rem;
}

.card .table-danger .card-description p {
  display: flex;
  flex-direction: row;
  justify-content: start;
}

.card .table-danger .card-description p {
  margin-right: 0.5rem;
  font-weight: lighter;
}

/*FOOTER*/
.border-shape {
  background: #1aa78d none repeat scroll 0 0;
  color: #fff;
  display: block;
  height: 3px;
  left: 0;
  margin: 20px auto;
  position: relative;
  right: 0;
  text-align: center;
  top: 0;
  width: 80px;
}
.border-shape::before {
  background: #0b7965 none repeat scroll 0 0;
  bottom: 0;
  content: "";
  height: 1px;
  left: 80px;
  margin: 0 auto;
  position: absolute;
  text-align: center;
  top: 1px;
  width: 100px;
}
.border-shape::after {
  background: #1aa78d none repeat scroll 0 0;
  bottom: 0;
  content: "";
  height: 1px;
  margin: 0 auto;
  position: absolute;
  right: 80px;
  text-align: center;
  top: 1px;
  width: 100px;
}

footer {
  width: 100%;
  background-color: #004b3e;
  color: #fff;
}
footer .border-shape {
  width: 40%;
}
footer .border-shape::before {
  width: 100%;
  left: 120px;
}
@media screen and (max-width: 768px) {
  footer .border-shape::before {
    width: 70%;
    left: 130px;
  }
}
footer .border-shape::after {
  width: 100%;
  right: 120px;
}
@media screen and (max-width: 768px) {
  footer .border-shape::after {
    width: 70%;
    right: 130px;
  }
}
footer .bottom_content section {
  padding: 1.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: Center;
}

.footer__footer-header {
  padding: 2rem;
  text-align: center;
}
.footer__footer-header__footer-contact span {
  font-weight: lighter;
  margin-bottom: 25px;
}
.footer__footer-header__footer-disclaimer p {
  color: #004b3e;
}
.footer__footer-header__footer-disclaimer__footer-disclaimer-header {
  font-size: 16px;
  margin-bottom: -45px;
}

.bottom_content a {
  margin: 0 20px;
  color: rgba(255, 255, 255, 0.7);
  transition: 0.5s;
}
.bottom_content a:hover {
  color: rgb(255, 255, 255);
}

.copyright {
  padding: 0.8em 0;
  background-color: #1e1e1e;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
}

@media (max-width: 820px) {
  .footer_header {
    padding: 1rem;
    display: block;
  }
  .footer_header section {
    display: block;
    text-align: center;
  }
  footer .bottom_content section {
    padding: 1rem;
    display: block;
    text-align: center;
  }
  footer .bottom_content section a {
    padding: 1rem;
    font-size: 12px;
    margin: 0 5px;
    display: inline-block;
  }
}
.fixed-footer {
  padding: 1em 0;
  background-color: #1e1e1e;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
}

.column-1 {
  flex: 0 0 8.3333333333%;
  width: 8.3333333333%;
}

.column-2 {
  flex: 0 0 16.6666666667%;
  width: 16.6666666667%;
}

.column-3 {
  flex: 0 0 25%;
  width: 25%;
}

.column-4 {
  flex: 0 0 33.3333333333%;
  width: 33.3333333333%;
}

.column-5 {
  flex: 0 0 41.6666666667%;
  width: 41.6666666667%;
}

.column-6 {
  flex: 0 0 50%;
  width: 50%;
}

.column-7 {
  flex: 0 0 58.3333333333%;
  width: 58.3333333333%;
}

.column-8 {
  flex: 0 0 66.6666666667%;
  width: 66.6666666667%;
}

.column-9 {
  flex: 0 0 75%;
  width: 75%;
}

.column-10 {
  flex: 0 0 83.3333333333%;
  width: 83.3333333333%;
}

.column-11 {
  flex: 0 0 91.6666666667%;
  width: 91.6666666667%;
}

.column-12 {
  flex: 0 0 100%;
  width: 100%;
}

.row-parent {
  display: flex;
  flex-wrap: wrap;
}/*# sourceMappingURL=style.css.map */
.reviews {
  //  background-color: #f8f8f8;
  padding: 5rem;
}

.reviews .card {
  background-color: #ebebeb;
  padding: 1.25rem;
  border: 1px solid #e5e5e5 !important;
  border-radius: 1.15rem;
  height: 100%;
  margin-bottom: 1rem;
}

@media screen and (max-width: $breakpoint-tablet) {
  .reviews {
    padding: 1.5rem;
  }

  .reviews .card {
    padding: 0.5rem 0.5rem;
    height: auto;
  }

  .reviews .col-12 {
    margin-bottom: 3rem;
  }
}

.reviews .card-body {
  height: 18rem;
  padding: 1.25rem 0.25rem;
}

.reviews .card-text {
  color: #000000;
  font-size: 1.05rem;
  margin-bottom: 3.5rem;
}

.reviews img.card-img-top {
  width: 30px;
}

.review-card-person {
  display: flex;
  flex-direction: row;
  //  justify-content: space-between;
  position: absolute;
  bottom: 20px;
}

.review-card-person-info {
  display: flex;
  flex-direction: row;
  justify-content: start;
}

.review-card-person img.person-img {
  margin-right: 15px;
  width: 80px;
  height: 100%;
}

.review-card-person .card-text-bottom {
  font-size: 20px;
  margin-bottom: 0;
}

.review-card-person .card-subt-text-bottom {
  font-size: 14px;
  color: #737373;
  line-height: 1.5;
}

@media screen and (max-width: $breakpoint-tablet) {
  .reviews .card-body {
    height: auto;
    padding: 1.5arem 0.25rem;
  }

  .reviews .card-text {
    font-size: 1rem;
    margin-bottom: 2.5rem;
  }

  .review-card-person {
    position: relative;
  }
  .review-card-person img.person-img {
    width: 40px;
    height: 40px;
  }
}

//attention-two-left
.attention-two-left .card {
  background: transparent;
  //  border: 1px solid #212529;
  height: 20rem;
  width: 100%;
  border-radius: 0;
  position: relative;
}
.attention-two-left .card:hover {
  //  background: #171717 !important;
  transition: 0.5s all;
  cursor: pointer;
}

@media screen and (max-width: $breakpoint-tablet) {
  .attention-two-left .card {
    margin-bottom: 5rem;
  }
}

.attention-two-left .card .card-bottom-img img {
  //  position: absolute;
  //  margin: auto;
  //  left: 15px;
  //  right: 0;
  //  bottom: -6rem;
  //  text-align: center;
  margin-top: 0.5rem;
  z-index: 1;
  width: 100%;
  //  height: 100%;
}

@media screen and (max-width: $breakpoint-tablet) {
  .attention-two-left .card .card-bottom-img img {
    width: 21rem;
  }
}

.attention-two-left .card-title {
  font-size: 16px;
  text-align: center;
  //  margin-bottom: 3rem;
  color: #3e42c1;
}

.attention-two-left .card-title .card-date {
  text-align: right;
  font-size: 12px;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
}

.attention-two-left .card-text {
  font-size: 20px;
  text-align: center;
  color: #b8c1cc;
}

.attention-two-left .card .card-footer {
  background: #171717;
  //  border-bottom-left-radius: 30px !important;
  //  border-bottom-right-radius: 30px !important;
  padding: 1rem 1rem;
}

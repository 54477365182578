//attention-tworight
.attention-two-right .card {
  background: #000000;
  //  border: 1px solid #212529;
  //  height: auto;
  //  width: 22rem;
  border-radius: 0;
  position: relative;
}

//@media screen and (max-width: $breakpoint-tablet) {
//  .attention-two-left .card {

//  }
//}

.attention-two-right .card-title {
  font-size: 16px;
  text-align: left;
  //  margin-bottom: 3rem;
  color: #3e42c1;
}

.attention-two-right .card-title .card-date {
  text-align: right;
  font-size: 12px;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
}

.attention-two-right p {
  font-size: 14px;
  text-align: right;
}

.attention-two-right .card-text {
  color: #b8c1cc;
  font-size: 20px;
  //  margin-bottom: 2rem;
}

.attention-two-right .card-text:hover {
  transition: 0.5s all;
  //  color: #c6923a;
  cursor: pointer;
}

.attention-two-right .card .card-bottom-img img {
  //  position: absolute;
  margin: auto;
  //  left: 0;
  //  right: 0;
  //  top: 0;
  //  bottom: 0;

  z-index: 1;
  width: 100%;
}
@media screen and (max-width: $breakpoint-tablet) {
  .attention-two-right .card .card-bottom-img img {
    bottom: -2rem;
    width: 22rem;
  }
}

.attention-two-right .card .card-footer {
  background: #171717;
  //  border-bottom-left-radius: 30px !important;
  //  border-bottom-right-radius: 30px !important;
  padding: 1rem 1rem;
}

@mixin bgImg($img) {
  background: url($img);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-blend-mode: overlay;
}

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flexVertical {
  display: flex;
  align-items: center;
}

@mixin btnCTA {
  background: #070547;
  color: $textWhite;
  border-color: $primaryBlack;
  border-radius: 1.5rem;
  padding: 10px 30px;

  &:hover {
    background: #e1eafd;
    color: #070547;
    border: 0.5px solid #070547;
  }
}

//@mixin btnCTAOutline {
//  //  background: linear-gradient(54deg, #333333bf, #333333);
//  border: 1px solid #333333;
//  color: $textBlack;
//  border-color: $primaryBlack;
//  border-radius: 1.5rem;
//  padding: 10px 30px;

//  &:hover {
//    background: linear-gradient(54deg, #ffffff, #333333bf);
//    color: $textBlack;
//  }
//}

//box shadows

@mixin boxShadowBorder($value...) {
  box-shadow: $value;
  -webkit-box-shadow: $value;
}

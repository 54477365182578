.ramadan {
  //  padding: 0px 0 0 0;
  //  margin-top: 5rem;
  width: 100%;
  //  @media screen and (max-width: $breakpoint-tablet) {
  //    margin-top: 8rem;
  //  }

  &.ngos {
    @media screen and (max-width: $breakpoint-tablet) {
      margin-top: 0rem;
    }
  }

  &__title {
    font-size: 36px;
    text-align: left;
    margin-bottom: 15px;
    @media screen and (max-width: $breakpoint-tablet) {
      margin-top: 75px;
      margin-bottom: 35px;
      font-size: 24px;
    }
  }

  &__subTitle {
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    color: $textGrey;
    margin-bottom: 1.5rem;
    @media screen and (max-width: $breakpoint-tablet) {
      font-size: 14px;
    }
  }

  &__project-link {
    text-decoration: none;
  }

  &__project-btn {
    @include btnCTA;
    transition: all 0.2s ease-in;
  }

  &__project-heading {
    font-size: 22px;
    margin-top: 5px;
    color: $primaryGreenDark;
    font-weight: bold;
    text-decoration: none;
  }

  &__project-icon {
    margin-bottom: 25px;
  }

  &__project-card {
    position: relative;
    margin-right: 1.5rem;

    @media screen and (max-width: $breakpoint-tablet) {
      margin-right: 0;
    }
  }

  &__project-org {
    margin-top: 20px;
  }

  &__project-created {
    font-style: italic;
    margin-top: -10px;
  }

  &__project-tag {
    //position: absolute;
    //bottom: 1.5rem;
    //right: 1rem;
    @media screen and (max-width: $breakpoint-tablet) {
      font-size: 10px;
    }
  }

  &__project-btntag {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 0;
    @media screen and (max-width: $breakpoint-tablet) {
      //  display: block;
      text-align: center;
      margin-bottom: 10px;
    }
  }

  &__project-row {
    position: relative;
    padding: 1.5rem;
  }

  &__project-img {
    //width: 100%;
    height: auto;
    object-fit: cover;
  }

  &__project-div-2 {
    position: relative;
  }

  &__project-img_2 {
    //position: absolute;
    //height: auto;
    width: 600px;
    z-index: -1;
    @media screen and (max-width: $breakpoint-tablet) {
      width: 330px;
    }
  }

  &__project-vdo-2 {
    position: absolute;
    top: -11.6rem;
    left: 4.7rem;
    @media screen and (max-width: $breakpoint-tablet) {
      top: -15.6rem;
      left: 2.7rem;
      width: 15rem;
    }
  }

  &__project-vdo {
    //width: 100%;
    //height: auto;
    //position: absolute;
    //bottom: -30rem;
    border-radius: 3rem;
  }
}

.ramadan-page {
  @media screen and (max-width: $breakpoint-tablet) {
    //margin-top: 0;
    padding-top: 1rem;
  }

  &__title {
    margin-top: 35px;
    margin-bottom: 35px;
  }
}

.review-section {
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: $breakpoint-tablet) {
    display: block;
    text-align: center;
    padding-bottom: 25px;
  }
}

.credentials {
  &__cred-img {
    width: 6.5vw;
    height: 10vh;
    padding: 4px;

    @media screen and (max-width: $breakpoint-tablet) {
      width: 12vw;
      height: 5vh;
      padding: 1px;
    }
  }
}

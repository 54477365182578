@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&display=swap");

.adplist-section {
  padding: 1rem;
  height: 350px;
  box-shadow: rgba(142, 151, 158, 0.15) 0px 4px 19px 0px;
  border-radius: 12px;
  overflow: hidden;
  width: 100%;
  max-width: 380px;
}

.attention {
  background: #080715;
  padding-bottom: 8rem;
  //  background-image: url("../img/bg/attentionbg.png");
}

.attention-one-middle-text {
  background: linear-gradient(
    93.4082deg,
    rgb(185 178 178) 0%,
    rgba(255, 255, 255, 0.5) 100%
  );
  display: inline-block;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  //  font-family: "Bricolage Grotesque", serif;;
}

@media screen and (max-width: $breakpoint-tablet) {
  .attention {
    padding-bottom: 1rem;
  }
}
.attention .card.attention-card {
  //  box-shadow: 2px 2px 5px rgb(0, 0, 0);
  //  border: 1px solid #212529;
  border-radius: 0;
  background: #0d0f12;
  margin-bottom: 2rem;
  width: 22rem;
  //  height: 20rem;
}

.attention .card.attention-card .card-body {
  background: #000000;
  color: #b8c1cc;
}

.attention .card.attention-card .card-title {
  font-size: 48px;
  font-family: "Bricolage Grotesque", serif;
  font-optical-sizing: auto;
  font-weight: bold;
}

.attention .card.attention-card .card-text {
  font-size: 14px;
  font-weight: lighter;
  color: #ffffff;
  font-family: "Courier Prime", monospace;
}

.attention .card.attention-card .card-footer {
  background: #171717;
  //  border-bottom-left-radius: 30px !important;
  //  border-bottom-right-radius: 30px !important;
  padding: 1rem 1rem;
}

.attention .card.attention-card .card-img-top {
  border-radius: 0 !important;
  border: 0px;
  background: #000;
}

.attention .card.attention-card .card-img-top img {
  width: 10em;
}

@media screen and (max-width: $breakpoint-tablet) {
  .attention .card.attention-card .card-img-top img {
    width: 7rem;
  }
}

//attention-left
.attention-one-left .card {
  background: #000000;
  border: 1px solid #212529;
  height: 20rem;
  width: 22rem;
  border-radius: 0;
  position: relative;
}
.attention-one-left .card:hover {
  background: #171717 !important;
  transition: 0.5s all;
  cursor: pointer;
}

@media screen and (max-width: $breakpoint-tablet) {
  .attention-one-left .card {
    margin-bottom: 5rem;
  }
}

.attention-one-left .card .card-bottom-img img {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 0rem;
  text-align: center;
  z-index: 1;
  width: 10rem;
}

.attention-one-left .card-title {
  font-size: 20px;
  text-align: left;
  margin-bottom: 3rem;
  color: #cdceff;
}

.attention-one-left .card-title .card-date {
  text-align: right;
  font-size: 12px;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
}

.attention-one-left .card-text {
  font-size: 36px;
  text-align: left;
  color: #b8c1cc;
}

.attention-one-left .card .card-footer {
  background: #171717;
  //  border-bottom-left-radius: 30px !important;
  //  border-bottom-right-radius: 30px !important;
  padding: 1rem 1rem;
}

//attention-right
.attention-one-right .card {
  background: #000000;
  border: 1px solid #212529;
  height: 20rem;
  width: 22rem;
  border-radius: 0;
  position: relative;
}

//@media screen and (max-width: $breakpoint-tablet) {
//  .attention-one-right .card {

//  }
//}

.attention-one-right .card-title {
  font-size: 20px;
  text-align: left;
  margin-bottom: 3rem;
  color: #cdceff;
}

.attention-one-right .card-title .card-date {
  text-align: right;
  font-size: 12px;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
}

.attention-one-right p {
  font-size: 14px;
  text-align: left;
}

.attention-one-right .card-text {
  color: #eeeeee;
}

.attention-one-right .card-text:hover {
  transition: 0.5s all;
  color: #3e42c1;
  cursor: pointer;
}

.attention-one-right .card .card-footer {
  background: #171717;
  //  border-bottom-left-radius: 30px !important;
  //  border-bottom-right-radius: 30px !important;
  padding: 1rem 1rem;
}

@import url("https://fonts.googleapis.com/css2?family=Amita:wght@400;700&family=Righteous&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap");

.bg-grey {
  background-color: $primaryBlack;
}

//  background-image: url("../img/bg/attentionbg.png");
//  background-size: cover;

.nav-center-text {
  font-size: 1.5rem;
}

.navbar-brand {
  position: relative;
}

.nav-job-role {
  position: absolute;
  top: 0;
  left: 5rem;
  font-family: "Lato", serif;
  font-size: 12px;
  justify-content: center;
  -webkit-text-stroke: 0.5px #5899e1;
  -webkit-text-fill-color: #5899e1;
  border: 1px solid #5899e1;
  padding: 4px;
  border-radius: 4px;
}

.navbar-menu-flex {
  display: flex;
  flex-direction: row;
}

.navbar-menu-works {
  display: flex;
  flex-direction: row;
  justify-content: start;
}

@media screen and (max-width: $breakpoint-tablet) {
  .navbar-menu-works {
    justify-content: center;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: $breakpoint-tablet) {
  .navbar-menu-flex {
    display: block;
  }
  .navbar-brand {
    font-size: 14px;
  }
}

@media screen and (max-width: $breakpoint-tablet) {
  .nav-center-text {
    display: none;
  }

  .navbar-toggler {
    display: none;
  }
}

.navbar {
  padding: 15px;
  transition: all 0.5s linear;
  z-index: $zindex-sticky;
  border-bottom: 0.5px solid #181a59;
  background-image: url("../img/bg/navbg.png");
  background-size: cover;
  //  &-dropdown-menu {
  //  }

  .nav-logo {
    width: 350px;
    @media screen and (max-width: $breakpoint-tablet) {
      width: 215px;
    }
  }

  &-nav {
    z-index: $zindex-sticky;
    .nav-item {
      .nav-link.active {
        color: $primaryGreenLight;
      }
      .nav-link {
        font-size: 18px;
        //background-color: $primaryGrey;
        color: $primaryWhite;
        border-radius: 3px;
        padding: 5px 10px 5px 10px;
        margin-left: 12px;
        margin-top: 10px;
        display: inline-block;
        &:hover,
        &:focus {
          color: $primaryGreenDark;
        }

        &.nav-dropdown-link {
          font-size: 16px;
          margin-top: 5px;
          margin-left: 5px;
          margin-right: 5px;
          padding: 5px;
        }

        .nav-link.nav-link-green {
          background-color: $primaryGreenLight;
        }

        @media screen and (max-width: $breakpoint-tablet) {
          color: $primaryBlack;
        }
      }
    }
  }

  &-toggler {
    @media screen and (max-width: $breakpoint-tablet) {
      background: #c8fff59e;
    }
  }

  .nav-link-black {
    color: $textBlack !important;
  }

  .nav-item .nav-link.nav-link-green {
    background-color: $primaryGreenLight !important;
  }

  .nav-link-white {
    color: $textWhite;
  }

  .navbar-collapse {
    @media screen and (max-width: $breakpoint-tablet) {
      background-color: $primaryWhite;
      padding: 15px 15px 25px 8px;
      border-radius: 7px;
      box-shadow: 0px 8px 1rem 0px rgba(0, 0, 0, 0.23);
      margin-top: 1rem;
    }
  }

  @media screen and (max-width: $breakpoint-tablet) {
    padding-top: 1.5rem;
  }
}

.bg-transp {
  background: #0d0f12;
}

.navbar-shadow {
  background: $primaryWhite;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
    0px 2px 4px -1px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
    0px 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.projects {
  padding: 30px 0 0 0;
  width: 100%;

  &__title {
    margin-bottom: 75px;
    @media screen and (max-width: $breakpoint-tablet) {
      margin-top: 0;
    }
  }

  //  &__title.home__first {
  //    @media screen and (max-width: $breakpoint-tablet) {
  //      margin-top: 12rem;
  //    }
  //  }

  &__saveProject {
    position: absolute;
    top: 1rem;
    right: 2rem;
  }

  &__project-row {
    @media screen and (max-width: $breakpoint-tablet) {
      --bs-gutter-y: 0.25rem;
      --bs-gutter-x: 0.25rem;
      margin-right: -10px;
      margin-left: -10px;
    }
  }

  &__project-link {
    text-decoration: none;
  }

  &__project-orgBold {
    font-size: 13px;
  }

  &__project-org {
    margin-top: 20px;
    @media screen and (max-width: $breakpoint-tablet) {
      font-size: 12px;
    }
  }

  &__project-btn {
    @include btnCTA;
    &:hover {
      color: $primaryGrey;
    }
    @media screen and (max-width: $breakpoint-tablet) {
      font-size: 12px;
      margin-top: 5px;
      margin-bottom: 5px;
      margin-right: 0;
      margin-left: 0;
    }
  }

  //  &__project-btn-outline {
  //    @include btnCTAOutline;
  //    &:hover {
  //      background: $primaryGrey;
  //    }
  //    @media screen and (max-width: $breakpoint-tablet) {
  //      font-size: 12px;
  //      margin-top: 5px;
  //      margin-bottom: 5px;
  //      margin-right: 0;
  //      margin-left: 0;
  //    }
  //  }

  &__SaveProject {
    position: absolute;
    top: 13.5rem;
    right: 1rem;
    z-index: auto;
    padding: 5px;
    color: $primaryGreenDark;
    border-radius: 50%;
    border: 1px solid $primaryGreenDark;
    border-color: $primaryGreenDark;
  }

  &__progress-bar {
    color: $primaryBlack;
    @media screen and (max-width: $breakpoint-tablet) {
      font-size: 12px;
    }
  }

  &__project-heading {
    font-size: 22px;
    margin-top: 5px;
    color: $primaryGreenDark;
    font-weight: bold;
    text-decoration: none;
    @media screen and (max-width: $breakpoint-tablet) {
      font-size: 14px;

      margin-bottom: 20px;
    }
  }

  &__project-icon {
    margin-bottom: 25px;
  }

  &__project-card {
    //position: relative;
    //margin-right: 1.5rem;

    @media screen and (max-width: $breakpoint-tablet) {
      margin-right: 0;
    }
  }

  &__project-btntag {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 0;
    @media screen and (max-width: $breakpoint-tablet) {
      display: block;
      text-align: center;
      margin-bottom: 10px;
    }
  }

  &__project-created {
    font-style: italic;
    margin-top: -10px;
    @media screen and (max-width: $breakpoint-tablet) {
      font-size: 11px;
      margin-bottom: -20px;
    }
  }

  &__project-tag {
    //position: absolute;
    //bottom: 1.5rem;
    //right: 1rem;
    @media screen and (max-width: $breakpoint-tablet) {
      font-size: 10px;
      //  position: relative;
      //  right: 0;
      //  left: 0;
      border-radius: 0;
      text-align: left;
    }
  }

  &__project-img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    @media screen and (max-width: $breakpoint-tablet) {
      height: 115px;
      object-fit: fill;
    }
  }

  &__project-cardBody {
    @media screen and (max-width: $breakpoint-tablet) {
      padding: 10px 10px;
    }
  }
}

.zakat {
  padding: 0 0 0 0;
  margin-bottom: 5rem;
  @media screen and (max-width: $breakpoint-tablet) {
    //margin-top: 8rem;
  }
}

.project_vdo_mid {
  @media screen and (max-width: $breakpoint-tablet) {
    width: 15rem;
  }
}

.measurer_img {
  width: 100%;
  @media screen and (max-width: $breakpoint-tablet) {
    margin-top: 0.5rem;
    width: 16rem;
  }
}

.project_vdo_sm {
  @media screen and (max-width: $breakpoint-tablet) {
    width: 15rem;
  }
}

//eight project - anlytic

.title-icon {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: start;
}

.title-icon img {
  height: 100%;
  margin-left: 1rem;
}

@media screen and (max-width: $breakpoint-tablet) {
  .title-icon {
    display: flex;
    justify-content: center;
  }
}

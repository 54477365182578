.contact {
  padding-top: 5rem;
  padding-bottom: 15rem;

  &__card {
    padding: 20px 0 0 0;
    display: inline-block;
    border: none;
  }

  &__title {
    color: $primaryBlack;
    margin-bottom: 25px;
    font-size: 30px;
  }

  &__sub-title {
    font-size: 18px;
    text-align: justify;
    line-height: 30px;
    margin-top: 25px;
    margin-bottom: 45px;
    span {
      color: $primaryGreenDark;
    }
  }

  &__about-disclaimer {
    p {
      color: $primaryGreenDark;
    }

    &__about-disclaimer-header {
      font-size: 16px;
      letter-spacing: 14px;
      margin-bottom: -45px;
    }
  }
}

.btnCTA_outline {
  //  background: linear-gradient(54deg, #333333bf, #333333);
  color: $textWhite;
  border: 0.5px solid #cccccc;
  border-radius: 1.5rem;
  padding: 10px 30px;

  &:hover {
    background: linear-gradient(54deg, #333333, #333333bf);
    color: $textWhite;
  }
}

.contact .card {
  display: inline-block;
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  border-radius: 6px;
  color: rgba(0, 0, 0, 0.87);
  background: transparent;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.text-danger {
  color: #f44336;
}

.card .table-danger {
  color: #ffffff;
  border-radius: 6px;
}
.card .table {
  padding: 20px 20px 0 20px;
}

.contact .card .table > :not(:first-child) {
  border: none !important;
}

.card .table-danger .author a,
.card .table-danger .ftr .stats,
.card .table-danger .category,
.card .table-danger .card-description .card .table-danger .card-caption {
  color: rgba(255, 255, 255, 0.8);
}

.card .card-caption {
  margin-bottom: 1rem;
}
.card .table-danger .card-description p {
  display: flex;
  flex-direction: row;
  justify-content: start;
}

.card .table-danger .card-description p {
  margin-right: 0.5rem;
  font-weight: lighter;
}

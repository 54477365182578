.work-header {
  padding: 5rem 0 5rem 0;
}

.work-header-subtext {
  font-size: 18px;
  color: #555555;
}

.work-header-img {
  text-align: center;
}

.work-header-img img {
  width: 30rem;
}

.btnCTAOutline {
  //  background: linear-gradient(54deg, #333333bf, #333333);
  border: 1px solid #333333;
  color: $textBlack;
  border-color: $primaryGrey;
  border-radius: 1.5rem;
  padding: 10px 30px;
  margin-left: 0.8rem;

  &:hover {
    background: linear-gradient(54deg, #ffffff, #f0eeeebf);
    color: $textBlack;
  }
}

@media screen and (max-width: $breakpoint-tablet) {
  .work-header {
    padding: 2.5rem 0 5rem 0;
  }

  .btnCTAOutline {
    margin-top: 2rem;
    margin-left: 0rem;
  }

  .work-header .work-header-subtext {
    font-size: 14px;
  }

  .work-header .work-header-img {
    margin-top: 3rem;
  }
  .work-header .work-header-img img {
    width: 17.5rem;
    text-align: center;
  }

  .work-header .work-header-img h5 {
    font-size: 14px;
  }
}

.works .col-md-6 img {
  background: #252424;
  padding: 0.8rem;
  border-radius: 15px;
}

.bg-banner {
  background-color: #fff1b5;
  margin: 5rem 0 6rem 0;
  padding: 3rem;
}

.bg-banner .row {
  //  justify-content: space-between;
}

//.banner-col {
//  display: flex;
//  justify-content: center;
//  align-items: center;
//  height: 100vh;
//  margin: 0;
//}

//.banner-content-container {

//}

.banner-text {
  position: relative;
  text-align: center;
}

.banner-text .white-background {
  position: absolute;
  top: 50%;
  left: 30%;
  width: 50%;
  height: 30%;
  background-color: #ffffff;
  transform: rotate(-5deg);
  z-index: 0;
}

.banner-text span {
  position: relative;
  z-index: 1;
}

.banner-text .large-text {
  color: #000000;
  font-size: 4rem;
  font-weight: bold;
  font-family: "Bricolage Grotesque", serif;
}

.banner-text .small-text {
  color: #000000;
  font-family: "Lato", serif;
  font-size: 18px;
  margin-left: 0.5rem;
}

//Header compontent scss

@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

//header bg
.headerBg {
  //  @include bgImg("/src/img/banner-2.jpeg");
  //  background-color: #f2f3f5;
  background-image: url("../img/bg/bgpp.svg");
  padding: 8rem 6rem 8rem 6rem;
  //  @include flexCenter;
  color: $primaryWhite;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: end;
  //  height: auto;
  //  margin-bottom: -5rem;
  @media screen and (max-width: $breakpoint-tablet) {
    //height: 129rem;
    padding: 8rem 1rem 3rem 1rem;
  }
}

//header bg

.header-btn-with-anim {
  @media screen and (max-width: $breakpoint-tablet) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.header-btn-with-anim a {
  margin-right: 1.5rem;
}
.header-btn-with-anim a {
  @media screen and (max-width: $breakpoint-tablet) {
    padding: 8px 0px;
    margin-top: 1rem;
    font-size: 14px;
    margin-right: 0;
  }
}

.header .ramadan__project-row {
  @media screen and (max-width: $breakpoint-tablet) {
    padding: 1rem;
  }
}

//animation - left

.header h1 {
  font-family: "Bricolage Grotesque", serif;
  font-weight: bold;
  font-size: 42px;
  line-height: 130%;

  color: #070547;
  //  letter-spacing: 0px;
  //  transition: all 2.1s;
  //  text-shadow: 1px 9px 17px rgba(63, 57, 57, 0.365);
}

@media screen and (max-width: $breakpoint-tablet) {
  .header h1 {
    font-size: 27px;
    line-height: 1.85rem;
  }
}

@media screen and (max-width: $breakpoint-tablet) {
  .header-image-card img {
    margin-top: 2rem;
    width: 100%;
  }
}

//more

.more {
  margin-bottom: 1.5rem;
}

//bg-glow

.glowable-text {
  position: relative;
  z-index: 2;
  font-size: 18px;
  color: #b8c1cc;
}

.bg-glow {
  height: 15rem;
  width: 15rem;
  //  background-color: #3e42c1;
  filter: blur(150px);
  border-radius: 150px;
  z-index: 0;
  position: absolute;
  opacity: 1;
}

//animation - right

.glow {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

// card - 1 border anim

.glowable-text .card.card-1 {
  //  width: 60vmin;
  //  height: 50vmin;
  display: grid;
  //  place-content: center;
  color: white;
  //  text-shadow: 0 1px 0 #000;
  //  padding: 1rem 1rem;
}

@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}

.glowable-text .card {
  border-radius: 8px;
}

// card - 1 border anim

.glowable-text .card.card-1 .card-body {
  text-align: left;
}
.glowable-text .card.card-2 {
  //  max-width: 20rem;
  background-color: #e1eafd;
  //  padding-bottom: 6rem;
  padding: 1rem 1rem 10rem 1rem;
  position: relative;
  border: none;
}

.intro .card-2 .card-body {
  padding-right: 3rem;
  height: auto;
}

.header-image-card img {
  width: 100%;
  //  height: 600px;
}

.card.card-2 .btnCTA {
  position: absolute;
  bottom: 20px;
}

.glowable-text .card.card-2 h4 {
  width: 32rem;
  line-height: 140%;
  font-size: 32px;
  color: #070547;
  font-weight: 400;
  font-family: "Lato", serif;
  font-optical-sizing: auto;
  //  font-weight: <weight>;
  font-style: normal;
}

@media screen and (max-width: $breakpoint-tablet) {
  .glowable-text .card.card-2 h4 {
    width: auto;
    line-height: 140%;
    font-size: 24px;
  }
}

.glowable-text .card.card-3 {
  border: none;
  background-color: #e1eafd;
}
.glowable-text .card.card-3 .card-3-text {
  color: #070547;
}

.glowable-text:hover {
  cursor: pointer;
}

[glow] .glowable-text {
  color: #000000;
}

@media screen and (max-width: $breakpoint-tablet) {
  .glow {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
  .glowable-text {
    font-size: 14px;
  }

  .glowable-text .card {
    max-width: 100%;
  }
}
//animation - right

.fun-text {
  padding-top: 5rem;
  padding-bottom: 10rem;
}

.fun-text h1 {
  font-family: "Amita", serif;
  font-size: 112px;
  color: #0d0f12;
}

@media screen and (max-width: $breakpoint-tablet) {
  .fun-text {
    padding-top: 5rem;
  }
  .fun-text h1 {
    font-family: "Amita", serif;
    font-size: 32px;
  }
  .bg-glow {
    height: 23rem;
    width: 8rem;
  }
}

.header {
  //  display: flex;
  //  justify-content: space-around;
  margin: 0 auto;
  width: 100%;
  max-width: 1300px;
  //  -webkit-box-align: center;
  //  -ms-flex-align: center;
  //  align-items: center;
  //  align-self: center;

  @media screen and (max-width: $breakpoint-tablet) {
    display: block;
  }

  &__flexTwo {
    width: 30%;
    position: relative;
    display: inline-block;
    @media screen and (max-width: $breakpoint-tablet) {
      width: 100%;
    }
  }

  &__headerCTA {
    @media screen and (max-width: $breakpoint-tablet) {
      font-size: 14px;
    }
  }

  &__gradientBg {
    height: 602px;
    margin: -180px auto 0;
    width: 1146px;
    position: relative;

    @media screen and (max-width: $breakpoint-tablet) {
      width: 130px;
    }
    @media screen and (max-width: $breakpoint-miniLaptop) {
      width: 130px;
    }

    &__circle1 {
      background-color: #0b7965b2;
      opacity: 0.7;
      -webkit-filter: blur(135px);
      filter: blur(135px);
      height: auto;
      padding-bottom: 46%;
      width: 70%;
      top: -16.3%;
      left: -14.6%;
    }

    &__circle2 {
      background-color: #0da4eac7;
      opacity: 0.65;
      -webkit-filter: blur(100px);
      filter: blur(100px);
      opacity: 0.6;
      height: auto;
      padding-bottom: 47.5%;
      width: 70%;
      top: -26.1%;
      left: 21.9%;
    }

    &__circle3 {
      background-color: #17d8b5;
      opacity: 0.63;
      webkit-filter: blur(102px);
      filter: blur(102px);
      height: auto;
      padding-bottom: 33.3%;
      width: 50.3%;
      top: 51.8%;
      left: 8.7%;
    }

    &__circle4 {
      background-color: #f5fffd;
      opacity: 0.43;
      webkit-filter: blur(140px);
      filter: blur(140px);
      height: auto;
      padding-bottom: 40%;
      width: 40%;
      top: 53.7%;
      right: 64.8%;
    }

    &__circle5 {
      background-color: #00bb98;
      opacity: 0.5;
      webkit-filter: blur(136px);
      filter: blur(136px);
      height: auto;
      padding-bottom: 100%;
      width: 70%;
      top: 69.3%;
      right: 0;
    }
  }

  &__gradCircle {
    position: absolute;
    border-radius: 50%;
  }

  &__content {
    //text-align: center;
    //margin-bottom: -5rem;

    &-h1 {
      font-size: 72px;
      color: $textBlack;
      text-align: center;
      //  margin-bottom: 15px;
      @media screen and (max-width: $breakpoint-tablet) {
        font-size: 28px;
      }
    }

    &-badges {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 1.5rem;
      //  margin-bottom: 2rem;

      @media screen and (max-width: $breakpoint-tablet) {
        margin-top: 1.5rem;
      }

      &__cred-img {
        width: 2rem;
        border-radius: 50%;
        margin-right: 2rem;

        @media screen and (max-width: $breakpoint-tablet) {
          width: 1.5rem;
          margin-right: 1rem;
        }
      }
    }

    &-links {
      font-size: 22px;
      font-weight: 500;
      color: #222222;
      text-align: center;
      text-decoration: none;
      margin-top: 1rem;

      //  &:hover {
      //    color: $primaryWhite;
      //  }

      @media screen and (max-width: $breakpoint-tablet) {
        font-size: 16px;
        margin-top: 1rem;
      }
    }

    &-span {
      font-size: 24px;
      color: $primaryGrey;
    }
  }

  @media screen and (max-width: $breakpoint-tablet) {
    //height: 60vh;
  }
}

.blog {
  padding: 1.5rem 15rem 5rem 15rem;
  @media screen and (max-width: $breakpoint-tablet) {
    padding: 1.5rem 1rem;
  }

  &__img {
    width: 100%;
    height: 25rem;
    margin-top: 5.5rem;
    @media screen and (max-width: $breakpoint-tablet) {
      height: 10rem;
    }

    //object-fit: cover;
  }
}

.lds-dual-ring {
  display: inline-block;
  width: 50px;
  height: 50px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 8px solid $primaryGreenRegular;
  border-color: $primaryGreenRegular transparent $primaryGreenRegular
    transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.details {
  padding: 4rem;
  width: 100%;

  @media screen and (max-width: $breakpoint-tablet) {
    padding: 8rem 1rem;
  }

  &__btn {
    @include btnCTA;

    &:hover {
      color: $primaryGrey;
    }
  }

  &__facebookIDDiv {
    margin-top: 25px;
  }

  &__heading {
    margin-top: 15px;
    margin-bottom: 15px;
    color: $primaryGreenDark;
    font-weight: bold;
  }

  &__img {
    width: auto;
    height: 300px;
    object-fit: contain;

    @media screen and (max-width: $breakpoint-tablet) {
      width: 320px;
    }
  }

  &__description {
    font-size: 22px;
    margin-top: 2rem;
    //margin-bottom: 5rem;
    color: $primaryBlack;
    font-weight: normal;
  }

  &__social {
    margin-top: 2rem;
    margin-bottom: 3rem;
  }

  &__projectLink {
    margin-left: 5px;
    color: $link;
  }

  &__card {
    position: relative;
    //margin-right: 1.5rem;

    @media screen and (max-width: $breakpoint-tablet) {
      margin-right: 0;
    }
  }

  &__org {
    margin-top: 20px;
  }

  &__created {
    font-style: italic;
    margin-top: -10px;
  }

  &__tag {
    position: absolute;
    top: 0;
    right: 1rem;
    @media screen and (max-width: $breakpoint-tablet) {
      font-size: 10px;
    }
  }

  &__dua {
    margin-top: 3rem;
    font-weight: 600;
    color: $primaryGreenDark;
  }
}

.bi-send-check-fill {
  margin-right: 15px;
  width: 24px;
  height: 24px;
}
